import React from 'react';
import { bool, string } from 'prop-types';
import { loadable } from 'nordic/lazy';

import Home from './home';
import { LAZYLOAD_IGNORE } from '../../../../utils/constants';
import { LazyComponentProvider } from '../../commons/lazy-component/context';

const lazyStrategy = {
  adn: LAZYLOAD_IGNORE,
};

const mapNavigationRecosSeed = [
  'navigation-recommendations-seed',
  'bookmarks-recommendations-seed',
  'top-sales-first-recommendations-grid',
  'navigation-recommendations-list',
  'navigation-recommendations-grid',
  'navigation-trend-recommendations-list',
  'navigation-trend-recommendations-grid',
  'bookmarks-recommendations-list',
  'bookmarks-recommendations-grid',
  'promotions-recommendations-list',
  'promotions-recommendations-grid',
  'second-best-navigation-trend-recommendations-list',
  'second-best-navigation-trend-recommendations-grid',
  'buying-trend-recommendations-grid',
  'buying-trend-recommendations-list',
  'navigation-related-recommendations-grid',
  'navigation-related-recommendations-list',
  'last-buy-recommendations-list',
  'last-buy-recommendations-grid',
  'second-best-buying-trend-recommendations-list',
  'second-best-buying-trend-recommendations-grid',
  'navigation-history-list',
  'cart-recommendations-list',
  'week-deal-recommendations-list',
  'week-deal-recommendations-grid',
];

const mapAvailableComponents = [
  'adn',
  'button-row-carousel',
  'categories-new-design',
  'collections',
  'complaints-book',
  'discovery',
  'dynamic-access',
  'exhibitor',
  'financial-user-info',
  'help-email',
  'home-registration',
  'label-card',
  'loyalty-benefits',
  'loyalty-partner-subscriptions',
  'loyalty',
  'national-insurance-info',
  'navigation-recommendations-seed',
  'navigation',
  'news-row',
  'official-stores',
  'payment-data',
  'recommendations-seed',
  'recommendations',
  'regret',
  'second-best-navigation-trend-recommendations-grid',
  'site-shopping-info',
  'widget-overview',
];

const componentMap = (type) => {
  let mapType = type;
  if (mapNavigationRecosSeed.includes(type)) {
    mapType = 'navigation-recommendations-seed';
  }

  return !mapAvailableComponents.includes(mapType) ? 'fallback-component' : `mobile/${mapType}`;
};
const componentLoader = (type) => loadable(() => import(`./available-components/${componentMap(type)}`));

function HomeMobile(props) {
  return (
    <LazyComponentProvider
      strategy={lazyStrategy}
      startInComponent={5}
      componentLoader={componentLoader}
    >
      <Home {...props} />
    </LazyComponentProvider>
  );
}

HomeMobile.propTypes = {
  isLab: bool.isRequired,
  isFirstVisit: bool.isRequired,
  cleanPwaServiceWorkers: bool.isRequired,
  lowEnd: bool,
  url: string,
  siteId: string,
};

export default HomeMobile;
